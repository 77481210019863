<template>
  <v-col class="text-left" cols="12">
    <v-card>
      <v-list dense v-if="libcats && libcats.length > 0">
        <v-subheader>Полки</v-subheader>

        <v-list-item-group v-model="libcatIndex" color="primary">
          <v-list-item
            v-for="(libcat, index) in libcats"
            :key="libcat.id"
            :index="index"
            @click="selectLibcat(libcat.id)"
            active
          >
            <!-- :class="item.val === sort ? 'active v-list-item--active' : ''" -->
            <v-list-item-content>
              <v-list-item-title
                >{{ libcat.name }} ({{ libcat.count }})</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-col>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  data: () => ({
    libcatIndex: 0,
  }),
  methods: {
    selectLibcat(id) {
      this.$emit("select", id);
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      libcats: "libraries/libcats",
    }),
  },
  mounted() {},
};
</script>
