<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-toolbar-title>{{ categoryTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container v-if="!loadPage">
      <v-card
        :loading="contentLoading"
        id="user-tabs"
        class="user-tabs mt-4 pb-4"
      >
        <v-tabs v-model="tab" @change="goTab" background-color="primary" dark>
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#book">Книги</v-tab>
          <v-tab href="#blog">Блоги</v-tab>
          <v-spacer></v-spacer>

          <v-menu
            v-if="tab == 'book' || tab == 'blog'"
            transition="slide-y-transition"
            left
            bottom
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-btn class="tab-button" text tile v-on="on">
                <span class="d-none d-sm-flex mr-1">Сортировка</span>
                <v-icon>$vuetify.icons.sort</v-icon>
              </v-btn>
            </template>
            <v-list class="text-right">
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(item, index) in sortings"
                  :key="index"
                  :class="item.val === sort ? 'active v-list-item--active' : ''"
                  @click="sortCategory(item.val)"
                >
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item value="book">
             <v-card flat>
                  <libcats-navigation
                    @select="selectLibcat"
                  ></libcats-navigation>
              
              <book-card
                v-for="(book, index) in books"
                :key="book.id"
                :book="book"
                :index="index"
                :library="true"
                :cabinet="false"
                @remove-library-modal="deleteLibraryModal"
                @move-library-modal="moveLibraryModal"
                >{{ book.id }}</book-card
              >
              <div
                v-if="!books || books.length == 0"
                class="tab-no-content text-center"
              >
                {{ noContent }}
              </div>
            </v-card>
          </v-tab-item>

          <v-tab-item value="blog">
            <v-card flat>
              <blog-card
                v-for="(blog, index) in blogs"
                :key="blog.id"
                :blog="blog"
                :index="index"
                :library="true"
                :admin="false"
                :cabinet="false"
                @remove-library-modal="deleteLibraryModal"
              ></blog-card>
              <div
                v-if="!blogs || blogs.length == 0"
                class="tab-no-content text-center"
              >
                {{ noContent }}
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <v-pagination
          v-if="pages > 1"
          @input="goPage"
          v-model="page"
          :length="pages"
          :total-visible="7"
          class="mt-2"
        ></v-pagination>
      </v-card>

      <v-dialog v-model="dialogDeleteBook" class="text-center" max-width="290">
        <v-card>
          <v-card-title class="headline justify-center"
            >Вы уверены?</v-card-title
          >

          <v-card-text class="text-center"
            >Что хотите удалить данную запись из библиотеки.</v-card-text
          >

          <v-card-actions class="justify-space-around">
            <v-btn color="red darken-1" text @click="deleteBook">Да</v-btn>

            <v-btn color="green darken-1" text @click="dialogDeleteBook = false"
              >Нет</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- <v-dialog v-model="libraryModal" class="text-center" max-width="290">
      <v-card>

        <libcats @select="selectLibcat"></libcats>

        <v-card-actions class="justify-space-around">
          <v-btn color="green darken-1" text @click="libraryModal = false"
            >Закрыть</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog> -->
      <libcats-dialog ref="libraryModal" @select="moveBook"></libcats-dialog>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import BookCard from "../book/BookCard.vue";
import BlogCard from "../blog/BlogCard.vue";
import LibcatsNavigation from "./LibcatsNavigation.vue";
// import Libcats from "./Libcats.vue";
import LibcatsDialog from "./LibcatsDialog.vue";
export default {
  components: {
    BookCard,
    BlogCard,
    LibcatsDialog,
    // Libcats,
    LibcatsNavigation,
  },
  data: () => ({
    sort: "new",
    tab: "book",
    categoryTitle: "Моя библиотека",
    dialogDeleteBook: false,
    deleteData: null,
    libcatId: -1,
  }),
  methods: {
    selectLibcat(id) {
      this.libcatId = id;
      this.getContent(this.page);
    },
    getContent(nextPage) {
      let app = this;
      this.$store.dispatch("libraries/getLibraries", {
        page: nextPage,
        sort: app.sort,
        type: app.tab,
        libcatId: app.libcatId,
      });
    },

    goPage(nextPage) {
      // console.log(nextPage);
      this.$vuetify.goTo("#user-tabs");
      this.getContent(nextPage);
    },
    goTab() {
      this.checkSorting();
      this.getSortings();
      this.page = 1;
      this.libcatId = -1;
      this.getContent(this.page);
    },
    getSortings() {
      let arr = [];
      // if (this.tab == "blog") {
      //   arr.push({ text: "По активности", val: "last_comment" });
      // }
      if (this.tab == "book") {
        // arr.push( { text: "По дате добавления", val: "last_lib" } );
        arr.push( { text: "По обновлению", val: "update500"} );
      }
      arr.push(
        // { text: "По обновлению", val: "update500" },
        { text: "По активности", val: "last_comment" },
        { text: "По новизне", val: "new" },
        {
          text: "По популярности",
          val: this.tab == "blog" ? "views" : "top",
        },
        { text: "По комментариям", val: "comments" }
      );
      return arr;
    },
    checkSorting() {
      // console.log(this.tab, this.sort);
      if (this.tab == "blog") {
        if (this.sort == "order") {
          this.sort = "last_comment";
        }
        else if (this.sort == "top") {
          this.sort = "views";
        }
        else if (this.sort == "update500") {
          this.sort = "new";
        }
      } else if (this.tab == "book") {
        if (this.sort == "last_comment") {
          this.sort = "order";
        }
        if (this.sort == "views") {
          this.sort = "top";
        }
      }
    },
    sortCategory(type) {
      if (type != this.sort) {
        this.sort = type;
        this.getContent(this.page);
      }
    },
    deleteLibraryModal(data) {
      this.deleteData = data;
      this.dialogDeleteBook = true;
    },
    moveLibraryModal(data) {
      this.moveData = data;
      this.$refs.libraryModal.open();
    },
    deleteBook() {
      let app = this;
      app.dialogDeleteBook = false;

      this.$store
        .dispatch("libraries/detachLibrary", {
          page: app.page,
          sort: app.sort,
          tableId: app.deleteData[0],
          tableType: app.deleteData[1],
          libcatId: app.libcatId,
          getContent: true,
        })
        .then(function (response) {
          // app.pages = response.data.books.last_page;
          app.deleteData = null;
          // если удалили последнюю книгу на странице перекинем на последнюю страницу
          if (
            response.data.content &&
            response.data.content.last_page < response.data.content.current_page
          ) {
            app.goPage(response.data.content.last_page);
          }
        });
    },
    moveBook(moveLibcatId) {
      let app = this;
      app.$refs.libraryModal.close();

      this.$store
        .dispatch("libraries/moveLibrary", {
          page: app.page,
          sort: app.sort,
          tableId: app.moveData[0],
          tableType: app.moveData[1],
          libcatId: app.libcatId,
          moveLibcatId: moveLibcatId,
          getContent: true,
        })
        .then(function (response) {
          app.moveData = null;
          // если удалили последнюю книгу на странице перекинем на последнюю страницу
          if (
            response.data.content &&
            response.data.content.last_page < response.data.content.current_page
          ) {
            app.goPage(response.data.content.last_page);
          }
        });
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      // books: "libraries/libraries",
      books: "libraries/books",
      blogs: "libraries/blogs",
      pages: "libraries/pages",
      content_loading: "libraries/content_loading",
    }),

    page: {
      get() {
        return this.$store.getters["libraries/page"];
      },
      set(val) {
        this.$store.commit("libraries/SET_PAGE", val);
      },
    },
    contentLoading() {
      if (this.content_loading) {
        return "success";
      }
      return false;
    },

    sortings() {
      return this.getSortings();
    },
    noContent() {
      if (this.content_loading) {
        return "Загружаем...";
      }
      return "Данных нет";
    },
  },
  mounted() {
    this.page = this.$route.query.page ? Number(this.$route.query.page) : 1;
    this.sort = this.$route.query.sort ? this.$route.query.sort : "update500";
    this.tab = this.$route.query.tab ? this.$route.query.tab : "book";
    this.getContent(1);
  },
};
</script>
